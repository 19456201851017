import '../../scss/contractor.scss';
import '../vendors/cssrelpreload.js';
import '../vendors/bootstrap.min.js';
import '../../node_modules/jquery.scrollto/jquery.scrollTo.min.js';
import '../custom/scroll-top.js';

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });
    $('#scroll').click(function () {
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });
    $('.thirdColumnPricing').hover(function () {
        $('.thirdColumnPricing > .row:first-child').toggleClass('backGroundHover');
        $('.thirdColumnPricing > .row').toggleClass('borderHover');
        $('.thirdColumnPricing > .row:last-child').toggleClass('borderBottomHover');
        $('.thirdColumnPricing > .row > div > i').toggleClass('tickColour');
    });
    $('#menuButton').click(function () {
        if ($(".showOnMobile").is(":hidden")) {
            $(".showOnMobile").slideDown("slow");
        } else {
            $(".showOnMobile").hide();
        }
    });
});




